import { MetaFunction } from 'react-router';
import RobinPage from '~/components/robin/RobinPage';
import { Route } from '~/config/enums';
import { generateMetaTags } from '~/services/utils/meta';

export const meta: MetaFunction = () => {
  return generateMetaTags({ title: 'Maintenance', route: Route.Maintenance });
};

export default function Maintenance() {
  return (
    <RobinPage pageTitle={'Maintenance'} buttonText={'Try Again'} icon={'retry'}>
      <h2 className="robin-demibold-24 text-black text-center">
        Uh oh!
        <br />
        We&apos;re currently performing maintenance!
      </h2>
      <p className="robin-medium-16 text-rf-gray-75">Please check back in a few more minutes.</p>
    </RobinPage>
  );
}
